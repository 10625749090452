<div *ngIf="!isTurn && !waitingForSecondPlayer" class="overlay-message">
    It is not your turn - waiting for oponents move
</div>
<div *ngIf="waitingForSecondPlayer" class="overlay-message">Waiting for second player</div>

<div class="board" *ngIf="!waitingForSecondPlayer">
    <canvas id="boardBackground" [appBoardBackground]="coordinates"  height="646px" width="646px"></canvas>
    <app-piece *ngFor="let color of (_blockchainService.positions$ | async), index as i" 
    [color]="color" [selected]="i === fromIndexIndex || i === toIndex" [removing]="i === removeIndex" (click)="pieceClicked(i)" 
    [ngStyle]="coordinates[i]">
    </app-piece>
</div>

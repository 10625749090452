<h2>Installation tuturial to play Merels <i>for free</i> on the Testnet</h2>
<ul>
    <li>Install <a href="https://metamask.io/">Metamask</a></li>
    <li>Metamask will be accessable through the fix icon on the top right of your browser</li>
    <li>(optinal) Add new accout</li>
    <li>Add the test network to your metmask (icon on the top right > settings > network > new network)
        <ul>
            <li>Name: Matic Mumbai Testnet</li>
            <li>RPC-URL: {{_blockchainService.connection.chainRpc}}</li>
            <li>Chain ID: {{_blockchainService.connection.chainId}}</li>
            <li>Currency Symbol: {{_blockchainService.connection.currencySymbol}}</li>
            <li>Block-Explorer URL: {{_blockchainService.connection.blockExplorerUrl}}</li>
        </ul>
    </li>
    <li>Make sure the new network is selected in your metamask</li>
    <li>Copy the public key of your (new) metamask account to your clipboard</li>
    <li>Get FREE Test-Matic with your public key from <a href="https://faucet.matic.network/">Matic Faucet</a></li>
    <li>Connect you account with Metamask now!
        <ul>
            <li>Open your metamask</li>
            <li>Click on the account you want to play with</li>
            <li>Confirm the connection</li>
        </ul>
    </li>
</ul>
